import React from "react"
import { navigate } from "gatsby"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Slider from "react-slick"
import Image from "components/atoms/Image"

const TryOurBestSellers = ({ content }) => {
  const { title, description, cards, bgColor } = content
  const sliderOption = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <Section className="try-our-best-sellers-section inner-padding" bgColor={bgColor.hex}>
      <Container>
        <h2 className="title">
          {title} <p className="tag-line">{description}</p>
        </h2>
        <div className="mproduct-slider-wrapper">
          <Slider {...sliderOption} className="mproduct-slider-row">
            {cards.length &&
              cards.map(card => {
                return (
                  <div className="mproduct-slider-cols" key={card._key} onClick={() => navigate(card.link)}>
                    <div className="mproduct-slider-image">
                      <Image image={card.image.asset} className="mproduct-img" isData />
                    </div>
                    <div className="mproduct-slider-content">
                      <div className="mps-title">{card.title}</div>
                      <div className="mps-content">{card.description}</div>
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      </Container>
    </Section>
  )
}

export default TryOurBestSellers
