import React from "react"
import CustomSelect from "components/atoms/CustomSelect"

const ProductCollectionsTop = ({ totalProducts, openFilterModal, sorts, handleSort }) => {
  return (
    <div className="product-collections-top">
      <div className="product-collections-title">
        <div className="product-collections-title">{totalProducts} Products</div>
      </div>
      <div className="product-collections-dropdown">
        <div className="list-dropdown">
          <CustomSelect
            placeholder="Popularity"
            options={sorts}
            classNames="custom-select-default"
            onChange={handleSort}
          />
          <button className="btn btn-secondary filter-by-btn" onClick={openFilterModal}>
            Filter by <i className="icon-filter"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductCollectionsTop
