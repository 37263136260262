import React from "react"
import { emptyBundle, bundleWrap } from "./emptyBundle.module.scss"

const EmptyBundle = () => {
  return (
    <div className={bundleWrap}>
      <div className={emptyBundle}>+</div>
    </div>
  )
}

export default EmptyBundle
