import React from "react"
import { popUpHeader, popUpTitleOpen, popUpTitleClosed, closeIcon } from "./popUpHeader.module.scss"

const PopUpHeader = ({ bundleItemsCount, openPopUp, setOpenPopUp }) => {
  return (
    <div className={popUpHeader} onClick={() => setOpenPopUp(!openPopUp)}>
      <h3 className={openPopUp ? popUpTitleOpen : popUpTitleClosed}>My Bundle ({bundleItemsCount} products)</h3>
      {openPopUp && <i className={`${closeIcon} h-icon-cancel`}></i>}
    </div>
  )
}

export default PopUpHeader
