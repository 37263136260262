import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const TakeTheKetoQuiz = ({ content }) => {
  const { bgImage, bgMobileImage, description, linkContent, title, imageOnly } = content

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(window.outerWidth <= 767)
    window.addEventListener("resize", () => {
      setIsMobile(window.outerWidth <= 767)
    })
  })

  return !imageOnly ? (
    <Section
      className="keto-quiz-section inner-padding"
      bgImg={bgImage.asset.url}
      bgMobileImage={bgMobileImage.asset.url}
    >
      <Container>
        <div className="keto-quiz-wrapper">
          {title && <h2 className="title">{title}</h2>}
          {description && <p className="tag-line">{description}</p>}
          <a href={linkContent?.linkUrl || "#"} className="link" target="_blank">
            {linkContent?.linktext}
          </a>
        </div>
      </Container>
    </Section>
  ) : (
    <div onClick={() => navigate(linkContent?.linkUrl || "#")}>
      <Image className="section-wrapper" image={!isMobile ? bgImage.asset : bgMobileImage.asset} isData />
    </div>
  )
}

export default TakeTheKetoQuiz
