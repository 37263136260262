import React, { useState } from "react"
import Container from "components/atoms/Container"
import CustomSelect from "components/atoms/CustomSelect"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const HomeHowDoWeStackUp = ({ content }) => {
  const { title, description, ourGummies, otherGummies } = content

  const ourGummiesOptions = ourGummies.map((item, i) => ({ value: item.name, label: item.name, key: i, data: item }))
  const otherGummiesOptions = otherGummies.map((item, i) => ({
    value: item.name,
    label: item.name,
    key: i,
    data: item,
  }))

  // State handling for user selection from custom-select
  const [data, setData] = useState({
    ourGummies: [ourGummies[0]],
    otherGummies: otherGummies[0],
    gummiesData: ourGummies[0],
  })

  const { bgColor } = data.gummiesData

  // Switch case statement for different selection
  const handleChange = name => e => {
    switch (name) {
      case "ourGummies":
        setData({ ...data, ourGummies: [e.data], otherGummies: otherGummies[e.key], gummiesData: e.data })
        break
    }
  }

  return (
    <Section bgColor={bgColor?.hex} className="inner-padding home-how-do-we-stack-up-section">
      <Container>
        <div className="we-stack-wrapper">
          <h2 className="title">{title}</h2>
          <p className="tag-line">{description}</p>
          <div className="we-stack-row">
            <div className="we-stack-cols">
              <div className="dropdown-wrapper">
                <CustomSelect
                  placeholder={data.gummiesData.name}
                  options={ourGummiesOptions}
                  onChange={handleChange("ourGummies")}
                />
              </div>
              <div className="bordered-wrapper">
                {data.ourGummies?.map((gummy, i) => (
                  <div key={i}>
                    <div className="stack-info">
                      <div className="stack-info-cols">
                        <div className="stack-info-title">{`${gummy.sugar}g`}</div>
                        <div className="stack-info-text">Sugar</div>
                      </div>
                      <div className="stack-info-cols">
                        <div className="stack-info-title">{`${gummy.netcarbs}g`}</div>
                        <div className="stack-info-text">Net Carbs</div>
                      </div>
                      <div className="stack-info-cols">
                        <div className="stack-info-title">{`${gummy.calories}`}</div>
                        <div className="stack-info-text">Calories</div>
                      </div>
                    </div>
                    <div className="stack-img">
                      <Image image={gummy?.image?.asset} isData className="stimg" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="we-stack-cols">
              <div className="dropdown-wrapper">
                <CustomSelect
                  placeholder={data.otherGummies.name}
                  options={otherGummiesOptions}
                  noDropdown
                  isDisabled
                />
              </div>
              <div className="bordered-wrapper">
                <div>
                  <div className="stack-info">
                    <div className="stack-info-cols">
                      <div className="stack-info-title">{`${data.otherGummies.sugar}g`}</div>
                      <div className="stack-info-text">Sugar</div>
                    </div>
                    <div className="stack-info-cols">
                      <div className="stack-info-title">{`${data.otherGummies.netcarbs}g`}</div>
                      <div className="stack-info-text">Net Carbs</div>
                    </div>
                    <div className="stack-info-cols">
                      <div className="stack-info-title">{`${data.otherGummies.calories}`}</div>
                      <div className="stack-info-text">Calories</div>
                    </div>
                  </div>
                  <div className="stack-img">
                    <Image image={data.otherGummies?.image?.asset} isData className="stimg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default HomeHowDoWeStackUp
