import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import ProductCollections from "components/molecules/ProductCollections"
import ProductsFilter from "components/molecules/ProductFilter"
import BundleSection from "components/molecules/BundleSection"
import { pull } from "lodash"
import { loadStamped } from "../../../utils/stamped"
import useWindowSize from "hooks/useWindowSize"
import useBlackFriday from "hooks/useBlackFriday"
import { useFlavorAsProduct } from "consts/helper"

const CollectionsProductsSection = ({ isBundle = false }) => {
  const { isMobile } = useWindowSize()
  const { enableDiscount } = useBlackFriday()
  const styleFromFixedBundle = !isMobile && isBundle ? { marginTop: "295px" } : null
  const mobileStyleFromFixedBundle = { marginTop: "150px" }

  const { allProducts, filterCategories, filterTags, sortTags, shopifyVariants, categories } = useStaticQuery(graphql`
    query HeaderQuery {
      allProducts: allSanityProduct(sort: { fields: order, order: ASC }) {
        nodes {
          ...Product
        }
      }
      filterCategories: allSanityFilteringCategories(sort: { fields: categoryKey }) {
        edges {
          node {
            _id
            categoryTitle
            categoryKey
          }
        }
      }
      filterTags: allSanityFilteringTags(sort: { fields: tagTitle }) {
        edges {
          node {
            _id
            tagTitle
            tagSlug
            tagKey {
              _id
              categoryTitle
            }
          }
        }
      }
      sortTags: allSanitySortingTags(sort: { fields: sortSlug }) {
        nodes {
          _id
          sortTitle
          sortSlug
        }
      }
      shopifyVariants: allShopifyProductVariant {
        nodes {
          sku
          storefrontId
          shopifyId
          inventoryQuantity
          price
          compareAtPrice
          title
        }
      }
      categories: allSanityCategory {
        nodes {
          id
          products {
            ...Product
          }
          title
        }
      }
    }
  `)

  allProducts.nodes.forEach(product => {
    if (enableDiscount) {
      product.flavors.forEach(allowDiscountItem => {
        allowDiscountItem.allowDiscount = true
      })
    } else {
      product.flavors.forEach(allowDiscountItem => {
        allowDiscountItem.allowDiscount = false
      })
    }
  })

  const [isFilterPopupOpened, setIsFilterOpened] = useState(false)
  const [filters, setFilters] = useState([])
  let [availableProds, setAvailableProds] = useState(allProducts.nodes)
  availableProds = useFlavorAsProduct(availableProds)
  availableProds.forEach(
    product =>
      (product.categories = categories.nodes
        .filter(c => c.products?.some(p => p.shopifyProductId === product.shopifyProductId))
        .map(c => c.title))
  )
  const [avSorts, setAvSorts] = useState([])
  useEffect(() => {
    loadStamped()

    if (sortTags?.nodes?.length && !avSorts.length) {
      let tempSorts = []
      sortTags.nodes.map(sort =>
        tempSorts.push({
          value: sort.sortSlug,
          label: sort.sortTitle,
        })
      )
      setAvSorts(tempSorts)
    }
  })

  const handleFilter = (filterKey, isCategory = false) => {
    let tempFilters = null
    if (filterKey && !isCategory) {
      tempFilters = filters
      filters.includes(filterKey) ? pull(tempFilters, filterKey) : tempFilters.push(filterKey)
      setFilters([...tempFilters])
    } else if (filterKey && isCategory) {
      tempFilters = [params]
      setFilters([params])
    } else if (!filterKey) {
      tempFilters = []
      setFilters([])
    }

    if (tempFilters.length > 0) {
      const filteredProds = []
      allProducts.nodes.map(prod => {
        const tempProd = prod
        const availableFlavors = prod.flavors.filter(
          flavor =>
            flavor?.filterAttr?.length && flavor?.filterAttr?.filter(attr => tempFilters.includes(attr.tagSlug))?.length
        )

        if (availableFlavors?.length) {
          tempProd.flavors = availableFlavors
          filteredProds.push(tempProd)
        }
      })
      setAvailableProds(filteredProds)
    } else if (tempFilters.length === 0) {
      setAvailableProds(allProducts.nodes)
    }
  }

  return (
    <Section className="collections-products-sections bg-section">
      <Container>
        {!isBundle && <h1 className="collection-all-header">All Kiss My Keto Products</h1>}
        {isBundle && <BundleSection />}
        <div
          className="collections-products-sections"
          style={isMobile && isBundle ? mobileStyleFromFixedBundle : styleFromFixedBundle}
        >
          <ProductsFilter
            categories={filterCategories.edges}
            tags={filterTags.edges}
            open={isFilterPopupOpened}
            onClose={() => setIsFilterOpened(false)}
            handleFilter={handleFilter}
            activeTags={filters}
            hideTop={isBundle}
          />
          <ProductCollections
            sorts={avSorts}
            products={availableProds}
            shopifyVariants={shopifyVariants?.nodes}
            openFilterModal={() => setIsFilterOpened(true)}
            isBundle={isBundle}
          />
        </div>
      </Container>
    </Section>
  )
}

export default CollectionsProductsSection
