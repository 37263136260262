import React from "react"
import { graphql } from "gatsby"
import Layout from "components/molecules/Layout"
import GraphQLErrorList from "components/atoms/Error/graphql-error-list"
import Advertisement from "components/organisms/Advertisement"
import CollectionsProductsSection from "components/organisms/CollectionsProductsSection"
import FaqSection from "components/organisms/FaqSection"
import HeroBannerSection from "components/organisms/HeroBannerSection"
import HomeHowDoWeStackUp from "components/organisms/HomeHowDoWeStackUp"
import KetoQuizAnswered from "components/organisms/KetoQuizAnswered"
import MadeWithGoodStuff from "components/organisms/MadeWithGoodStuff"
import NoteFromAlex from "components/organisms/NoteFromAlex"
import ShopOurKetoProducts from "components/organisms/ShopOurKetoProducts"
import SignUpSection from "components/organisms/SignUpSection"
import TakeTheKetoQuiz from "components/organisms/TakeTheKetoQuiz"
import TryOurBestSellers from "components/organisms/TryOurBestSellers"
import UsVsThemHowDoWeStackUp from "components/organisms/UsVsThemHowDoWeStackUp"
import WhatsOurSecretSause from "components/organisms/WhatsOurSecretSauce"
import WordOnTheStreet from "components/organisms/WordOnTheStreet"
import SEO from "components/atoms/SEO"
import { useZendeskHide } from "hooks/useZendesk"

export const query = graphql`
  query PageTemplateQuery($id: String!, $pageTitle: String!) {
    page: sanityPage(_id: { eq: $id }, title: { eq: $pageTitle }) {
      meta {
        metaTitle
        metaDescription
      }
      _id
      title
      _rawContentBlocks(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
    }
  }
`

const PageTemplate = props => {
  const {
    data: {
      page: { title, _rawContentBlocks, meta },
    },
    errors,
  } = props

  useZendeskHide()

  return (
    <Layout>
      {errors && (
        <>
          <h2>GraphQL Error</h2>
          <GraphQLErrorList errors={errors} />
        </>
      )}
      <SEO title={meta?.metaTitle} description={meta?.metaDescription} />

      {_rawContentBlocks?.map((content, idx) => (
        <div key={idx}>
          {content._type === "logosBlock" && <Advertisement content={content} />}
          {content._type === "mainHeroBlock" && <HeroBannerSection content={content} />}
          {content._type === "answeredQuestionBlock" && <KetoQuizAnswered content={content} />}
          {content._type === "aboutNoteBlock" && <NoteFromAlex content={content} />}
          {content._type === "ketoProductsBlock" && <ShopOurKetoProducts content={content} />}
          {content._type === "stackUpBlock" && (
            <>
              <HomeHowDoWeStackUp content={content} />
              <MadeWithGoodStuff />
            </>
          )}
          {content._type === "bestSellerBlock" && <TryOurBestSellers content={content} />}
          {content._type === "miniHeroBlock" && <TakeTheKetoQuiz content={content} />}
          {content._type === "productStackUpBlock" && <UsVsThemHowDoWeStackUp content={content} />}
          {content._type === "usVsThemSecretSauceBlock" && <WhatsOurSecretSause content={content} />}
          {content._type === "reviewVideoBlock" && <WordOnTheStreet content={content} />}
          {content._type === "faqSection" && <FaqSection content={content} />}
        </div>
      ))}

      {title === "Collections" && <CollectionsProductsSection />}
      {title === "Bundles" && <CollectionsProductsSection isBundle />}
      <SignUpSection />
    </Layout>
  )
}

export default PageTemplate
