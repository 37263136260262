import React from "react"
import { Link } from "gatsby"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Rating from "components/atoms/Rating"
import Image from "components/atoms/Image"

import ArrowRight from "assets/images/arrow-right.svg"

const HeroBannerSection = ({ content }) => (
  <Section className="inner-padding hero-section" bgColor={content.bgHexCode}>
    <Container>
      <div className="hero-section-flex">
        <div className="hero-sec-img">
          <Image image={content.image.asset} className="hero-img" isData />
        </div>
        <div className="hero-content">
          <h1 className="hero-title">{content.title}</h1>
          <div className="hero-button">
            <Link to={content.link.linkUrl} className="btn btn-trans">
              {content.link.linktext} <img src={ArrowRight} className="arrow-icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="hero-rating">
        {content.ratingBlock.title}
        <Rating ratingType="star-rating" ratingPercent={`${content.ratingBlock.rate * 20}%`} />
      </div>
    </Container>
  </Section>
)

export default HeroBannerSection
