import React, { useEffect, useState, useContext } from "react"
import { StoreContext } from "context/store"
import { SortProducts } from "consts/helper"
import gtag, { gtagProductMapping } from "../../../utils/gtag"
import ProductCollectionsTop from "components/molecules/ProductCollections/ProductCollectionsTop/ProductCollectionsTop"
import ProductCollectionsItem from "components/molecules/ProductCollections/ProductCollectionsItem/ProductCollectionsItem"
import RebuyProducts from "components/molecules/Rebuy/RebuyProduts"

const ProductCollections = ({ products, openFilterModal, shopifyVariants, sorts, isBundle }) => {
  const [sortedProds, setSortedProds] = useState([])
  const { isReBuyPopupOpen, setReBuyPopupOpen } = useContext(StoreContext)

  useEffect(() => {
    setSortedProds(products)
    gtag("dl_view_item_list", {
      event_id: "collection" + Math.floor(Date.now() / 1000),
      ecommerce: {
        currencyCode: "USD",
        impressions: products
          .map((p, index) => {
            let selVariant = p.flavors?.[0] || {}
            selVariant = {
              ...selVariant,
              ...shopifyVariants.filter(variant => variant.sku === p.flavors?.[0].sku)?.[0],
            }
            return {
              ...p,
              variant: selVariant.title,
              variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
              kmkPrice: selVariant.price,
              kmkQuantity: 1,
              sku: selVariant.sku,
              kmkImage: selVariant.images[0],
              position: index + 1,
            }
          })
          .map(gtagProductMapping),
      },
    })
  }, [products])

  useEffect(() => {
    if (sorts[0]) {
      handleSort(sorts[0])
    }
  }, [sorts])

  const handleSort = sort => {
    const sortKey = sort.value.slice(2)
    const result = SortProducts(sortKey, products)
    setSortedProds(result)
  }

  return (
    <div className="product-collections-wrap">
      {!isBundle && (
        <ProductCollectionsTop
          totalProducts={products.length}
          openFilterModal={openFilterModal}
          sorts={sorts}
          handleSort={handleSort}
        />
      )}
      {isReBuyPopupOpen && <RebuyProducts onClose={() => setReBuyPopupOpen(false)} />}
      <div className="product-collections">
        {sortedProds?.map((product, index) => (
          <ProductCollectionsItem
            product={product}
            shopifyVariants={shopifyVariants}
            key={index}
            index={index}
            isBundle={isBundle}
          />
        ))}
      </div>
    </div>
  )
}

export default ProductCollections
