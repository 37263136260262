import React from "react"

const ProductFilter = ({ open, onClose, handleFilter, categories, tags, activeTags, hideTop }) => {
  return (
    <div className={open ? "product-filter-wrapper active" : "product-filter-wrapper"}>
      <div className={open ? "backdrop active" : ""} onClick={onClose} />
      <div className={open ? "product-filter active" : "product-filter"}>
        {!hideTop && (
          <div className="product-filter-top">
            <div className="product-collections-title">FILTER BY</div>
          </div>
        )}
        <div className="close-drawer" onClick={onClose}>
          <i className="icon-close"></i>
        </div>
        <div className="product-filter-inner">
          {categories?.map(category => (
            <div key={category?.node?._id}>
              <div className="filter-title">{category.node.categoryTitle}</div>
              {tags?.map(
                tag =>
                  tag?.node?.tagKey?._id === category?.node?._id && (
                    <div className="filter-item checkbox-item" key={tag.node?._id}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={`chk-${tag.node?._id}`}
                        onChange={() => handleFilter(tag.node?.tagSlug)}
                        checked={activeTags.includes(tag.node?.tagSlug)}
                      />
                      <label className="checkbox-label" htmlFor={`chk-${tag.node?._id}`}>
                        <span className="check"></span>
                        {tag.node?.tagTitle}
                      </label>
                    </div>
                  )
              )}
            </div>
          ))}
          <div className="mobile-button">
            <button className="btn btn-primary">APPLY FILTERS</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductFilter
