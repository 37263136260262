import { greenText } from "components/molecules/BundleSection/styles.module.scss"
import React from "react"
import { title, congratsText } from "./progressInfo.module.scss"

const ProgressInfo = ({ isMobile, step, distance, offList, reachedPrices, className }) => {
  return (
    <div className={className}>
      {step !== 4 && !isMobile && (
        <h5 className={title}>
          You are <span className={greenText}>${distance.toFixed(2)}</span> away from a {offList[step - 1]}% OFF
          discount!
        </h5>
      )}
      {step !== 4 && isMobile && (
        <h5>
          Spend at least <span className={greenText}>${reachedPrices[step - 1]}</span> to get a{" "}
          <span className={greenText}>{offList[step - 1]}% OFF </span>
          discount!
        </h5>
      )}
      {step === 4 && <h5 className={congratsText}>Congratulations! You have reached the maximum discount! </h5>}
    </div>
  )
}

export default ProgressInfo
