import React from "react"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"
import { Link } from "gatsby"

const ShopOurKetoProducts = ({ content }) => {
  const { title, ketoProducts } = content
  return (
    <Section className="inner-padding our-keto-product-section">
      <Container>
        <div className="our-keto-product-wrapper">
          <h2 className="title">{title}</h2>
          <div className="keto-row">
            {ketoProducts &&
              ketoProducts.map(product => (
                <div className="keto-cols" key={product._id}>
                  <div className="keto-cols-inner">
                    <Link to={product?.linkUrl}>
                      <Image image={product.bgImage.asset} className="okp-img" isData />
                      <div className="okp-title">{product.productTitle}</div>
                      <div className="okp-content">
                        <div className="okp-text">{product.description}</div>
                        <div className="okp-link link">Learn more</div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default ShopOurKetoProducts
