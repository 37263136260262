import React from "react"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const NoteFromAlex = ({ content }) => {
  const { aboutText, bgHexCode, image, signature, title } = content
  return (
    <Section className="note-section inner-padding">
      <Container>
        <div className="note-wrapper">
          <h2 className="title">{title}</h2>
          <div className="note-inner">
            <div className="note-cols">
              <Image image={image.asset} className="note-img" isData />
            </div>
            <div className="note-cols">
              <p className="note-text">{aboutText}</p>
              <div className="signature">
                <Image image={signature.asset} className="sign" isData />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default NoteFromAlex
