import React from "react"
import { atcMobileContainer, atcBtn, atcPrice, addToCard } from "./addToCard.module.scss"

const AddToCard = ({ step, atcOriginPrice, handleAddToCart, totalPrice, offList, isMobile }) => {
  return (
    <div className={isMobile ? atcMobileContainer : atcBtn}>
      <h5 className={atcPrice}>
        <span className={step !== 1 && atcOriginPrice}>${totalPrice.toFixed(2)}</span>
        {step !== 1 && <span>${((totalPrice * (100 - offList[step - 2])) / 100).toFixed(2)} </span>}
      </h5>
      <button className={`btn-primary ${addToCard}`} onClick={handleAddToCart}>
        Add to cart
      </button>
    </div>
  )
}

export default AddToCard
