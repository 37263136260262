import React from "react"
import { progressLabel } from "./progressBar.module.scss"

const ProgressBarContainer = ({
  step,
  totalPrice,
  fillFirst,
  fillSecond,
  fillThird,
  isMobile,
  percent35,
  styleBar,
}) => {
  return (
    <div className={`progress-bundle-bars`}>
      <div className="progress-bar-wrapper">
        <div className="custom-progress-bar" style={styleBar}>
          <div className="green-bar" style={{ width: `${fillFirst}%` }}></div>
        </div>
        <span className={`${step !== 1 ? "active" : ""} ${progressLabel}`}>
          {`15% ${isMobile ? "(spend $69+)" : "(spend $69 or more)"}`}
        </span>
      </div>
      <div className="progress-bar-wrapper">
        <div className="custom-progress-bar" style={styleBar}>
          <div className="green-bar" style={{ width: `${fillSecond}%` }}></div>
        </div>
        <span className={`${step !== 1 && step !== 2 ? "active" : ""} ${progressLabel}`}>
          {`25% ${isMobile ? "(spend $99+)" : "(spend $99 or more)"}`}
        </span>
      </div>
      <div className="progress-bar-wrapper">
        <div className="custom-progress-bar" style={styleBar}>
          <div className="green-bar" style={{ width: `${fillThird}%` }}></div>
        </div>
        <span
          className={`${
            step !== 1 && step !== 2 && step !== 3 && totalPrice >= percent35 ? "active" : ""
          } ${progressLabel}`}
        >
          {`35% ${isMobile ? "(spend $129+)" : "(spend $129 or more)"}`}
        </span>
      </div>
    </div>
  )
}

export default ProgressBarContainer
