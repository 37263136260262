import React, { useState } from "react"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const WhatsOurSecretSause = ({ content: { title, secretSauce, image } }) => {
  const [selectedFaq, setSelectedFaq] = useState(0)

  return (
    <Section className="whats-our-secret-sauce-section section-wrapper">
      <Container>
        <h1 className="title">{title}</h1>
        <div className="whats-our-secret-sauce-details-wrapper">
          <div className="whats-our-secret-sauce-accordion-wrapper">
            <div className="accordion-wrapper">
              {secretSauce.map((info, index) => (
                <div
                  className={selectedFaq === index ? "accordion-item active" : "accordion-item"}
                  onClick={() => setSelectedFaq(index)}
                  key={index}
                >
                  <div className="accordion-link">
                    {info.title}
                    <span className="accordion-icon"></span>
                  </div>
                  <div className="accordion-answer">
                    <p className="accordion-answer-text">{info.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="product-image">
            <Image image={image.asset} isData />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default WhatsOurSecretSause
