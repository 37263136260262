import React, { useState, useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { StoreContext } from "context/store"
import ProgressInfo from "../ProgressInfo/ProgressInfo"
import ProgressBarContainer from "../ProgressBar/ProgressBar"
import { cn } from "consts/helper"
import {
  container,
  bundleContainer,
  atcOriginPrice,
  mobilePopUp,
  open,
  progressMobileContainer,
} from "./styles.module.scss"
import useWindowSize from "hooks/useWindowSize"
import EmptyBundle from "components/molecules/EmptyBundle/EmptyBundle"
import BundleList from "components/molecules/BundleItem/BundleList"
import AddToCard from "components/molecules/AddToCard/AddToCard"
import PopUpHeader from "components/molecules/PopUpHeader/PopUpHeader"

const percent15 = 69
const percent25 = 99
const percent35 = 129
const offList = [15, 25, 35]
const reachedPrices = [69, 99, 129]

const BundleSection = () => {
  const { setLineItems, bundleItems, setBundleItems, headerShow, isBlackFri } = useContext(StoreContext)
  const [totalPrice, setTotalPrice] = useState(0)
  const [step, setStep] = useState(1)
  const [fillFirst, setFillFirst] = useState(0)
  const [fillSecond, setFillSecond] = useState(0)
  const [fillThird, setFillThird] = useState(0)
  const [distance, setDistance] = useState(percent15)
  const [openPopUp, setOpenPopUp] = useState(false)
  const { isMobile } = useWindowSize()

  useEffect(() => {
    if (bundleItems.length > 0) {
      let tempPrice = 0
      bundleItems.map(bundle => {
        tempPrice += bundle.kmkAllowDiscount ? Number(bundle.kmkPrice) : Number(bundle.kmkPrice * bundle.quantity)
      })

      if (tempPrice < percent15) {
        setStep(1)
        setFillFirst(((tempPrice / percent15) * 100).toFixed(0))
        setFillSecond(0)
        setFillThird(0)
        setDistance(percent15 - tempPrice)
      } else if (percent15 <= tempPrice && tempPrice < percent25) {
        setStep(2)
        setFillFirst(100)
        setFillSecond(((tempPrice / percent25) * 100).toFixed(0))
        setFillThird(0)
        setDistance(percent25 - tempPrice)
      } else if (percent25 <= tempPrice && tempPrice < percent35) {
        setStep(3)
        setFillFirst(100)
        setFillSecond(100)
        setFillThird(((tempPrice / percent35) * 100).toFixed(0))
        setDistance(percent35 - tempPrice)
      } else if (percent35 <= tempPrice) {
        setStep(4)
        setFillFirst(100)
        setFillSecond(100)
        setFillThird(100)
        setDistance(0)
      }

      setTotalPrice(tempPrice)
    } else {
      setStep(1)
      setFillFirst(0)
      setFillSecond(0)
      setFillThird(0)
      setDistance(percent15)
    }
  })

  const hideBundleOnScrollDeskTop = isBlackFri
    ? { transform: "translateY(-136px)" }
    : { transform: "translateY(-178px)" }
  const hideBundleOnScrollMobile = isBlackFri ? { transform: "translateY(-78px)" } : { transform: "translateY(-136px)" }
  const styleScroll = isMobile ? hideBundleOnScrollMobile : hideBundleOnScrollDeskTop

  const barStyleBg = openPopUp ? { background: "#DDDDDD" } : null

  const removeBundle = idx => {
    const tempBundles = bundleItems.slice(0).filter((bundle, bundleId) => bundleId !== idx)
    setBundleItems(tempBundles)
  }

  const handleAddToCart = () => {
    const tempBundles = bundleItems
    if (step !== 1) {
      tempBundles.map(bundle => {
        bundle.kmkPrice = ((bundle.kmkPrice * (100 - offList[step - 2])) / 100).toFixed(2)
      })
    }
    setBundleItems([])
    setLineItems(tempBundles)
  }

  return (
    <>
      <Helmet>
        {/* We add it by Ionut's request */}
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {!openPopUp && (
        <div className={`${container}`} style={headerShow ? styleScroll : null}>
          <div className="container">
            <>
              <ProgressInfo
                distance={distance}
                offList={offList}
                reachedPrices={reachedPrices}
                step={step}
                isMobile={isMobile}
              />
              <ProgressBarContainer
                step={step}
                isMobile={isMobile}
                fillFirst={fillFirst}
                fillSecond={fillSecond}
                fillThird={fillThird}
                totalPrice={totalPrice}
                percent35={percent35}
                styleBar={barStyleBg}
              />
            </>
            {!isMobile && (
              <BundleList bundleItems={bundleItems} removeBundle={removeBundle}>
                <EmptyBundle />
                <EmptyBundle />
                {bundleItems?.length > 0 && (
                  <AddToCard
                    step={step}
                    handleAddToCart={handleAddToCart}
                    totalPrice={totalPrice}
                    offList={offList}
                    atcOriginPrice={atcOriginPrice}
                  />
                )}
              </BundleList>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div className={cn(mobilePopUp, openPopUp ? open : "")}>
          <PopUpHeader openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} bundleItemsCount={bundleItems?.length} />
          <div className={bundleContainer}>
            <div className={progressMobileContainer}>
              <ProgressInfo
                distance={distance}
                offList={offList}
                reachedPrices={reachedPrices}
                step={step}
                isMobile={isMobile}
              />
              <ProgressBarContainer
                step={step}
                isMobile={isMobile}
                fillFirst={fillFirst}
                fillSecond={fillSecond}
                fillThird={fillThird}
                totalPrice={totalPrice}
                percent35={percent35}
                styleBar={barStyleBg}
              />
            </div>
            <BundleList bundleItems={bundleItems} removeBundle={removeBundle}>
              <EmptyBundle />
            </BundleList>
            {bundleItems?.length > 0 && (
              <AddToCard
                isMobile={isMobile}
                step={step}
                handleAddToCart={handleAddToCart}
                totalPrice={totalPrice}
                offList={offList}
                atcOriginPrice={atcOriginPrice}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default BundleSection
