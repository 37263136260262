import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import CustomSelect from "components/atoms/CustomSelect"
import StackUpTable from "components/molecules/StackUpTable"

const headers = ["Sugar", "Net Carbs", "Fiber", "Calories", "Carbs", "Proteins"]

const HowDoWeStackUp = ({ content: { title, description } }) => {
  const { allProducts } = useStaticQuery(graphql`
    query UsVsThemStacks {
      allProducts: allSanityProduct {
        nodes {
          ...Product
        }
      }
    }
  `)

  const [productStacks, setProductStacks] = useState()
  const [ourProducts, setOurProducts] = useState()
  const [otherProducts, setOtherProducts] = useState()
  const [selProd, setSelProd] = useState()

  useEffect(() => {
    let tempStacks = []
    let tempOurProducts = []
    let tempOtherProducts = []
    allProducts.nodes.map(product => {
      const stackUpBlock = product?._rawContentBlocks?.filter(content => content._type === "productStackUpBlock")
      stackUpBlock?.length &&
        tempStacks.push({
          title: product.name,
          stackUp: stackUpBlock[0],
        })
    })

    tempStacks.map(stack => {
      tempOurProducts.push({
        label: "Our - " + stack.title,
        value: stack.title,
      })

      tempOtherProducts.push({
        label: "Other - " + stack.title,
        value: stack.title,
      })
    })

    setProductStacks(tempStacks)
    setOurProducts(tempOurProducts)
    setOtherProducts(tempOtherProducts)
    setSelProd(tempStacks[0])
  }, [allProducts])

  const handleChange = selProd => {
    setSelProd(productStacks.filter(stack => stack.title === selProd.value)[0])
  }

  return (
    <Section className={"us-vs-them-stack-up-section inner-padding light-sky-blue-bg"}>
      <Container>
        <h1 className="title text-center">{title}</h1>
        <div className="comparison-details-wrapper">
          <div className="tag-line-text">{description}</div>
          {selProd && (
            <div className="comparision-wrapper">
              <CustomSelect placeholder={"Our - " + selProd.title} onChange={handleChange} options={ourProducts} />
              <div className="vs-text">Vs.</div>
              <CustomSelect placeholder={"Other - " + selProd.title} isDisabled options={otherProducts} noDropdown />
            </div>
          )}
        </div>
        {selProd && <StackUpTable stacks={selProd.stackUp.stacks} headers={selProd.stackUp.headers} />}
      </Container>
    </Section>
  )
}

export default HowDoWeStackUp
