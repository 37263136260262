import React from "react"

const Rating = ({ ratingType, ratingPercent }) => (
  <div className="rating">
    <div className={ratingType}>
      <span style={{ width: ratingPercent }}></span>
    </div>
  </div>
)

export default Rating
