import React, { useRef, useState } from "react"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"

import Slider from "react-slick"
import ReactPlayer from "react-player"
import PlayIcon from "../../../assets/images/play-video.svg"
import TapToPlaySoundVideo from "components/atoms/TapToPlaySoundVideo"
import { Link } from "gatsby"

const WordOnTheStreet = ({ content }) => {
  const sliderOption = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  const { title, videoUrl, reviews } = content
  const [muted, setMuted] = useState(true)
  const [bgVideo, setBgVideo] = useState(true)
  const videoRef = useRef("videoRef")
  const playVideo = e => {
    e.preventDefault()
    setMuted(false)
    videoRef.current.seekTo(0)
  }

  return (
    <Section className="on-street-section inner-padding">
      <Container>
        <div className="on-street-wrapper">
          <h2 className="title">{title}</h2>
          <div className="on-street-row">
            <div className="on-street-cols">
              <Slider {...sliderOption} className="on-street-review">
                {reviews?.map(review => (
                  <div className="on-street-review-inner" key={review._id}>
                    <div className="street-rating">
                      <div className="rating">
                        <span className="icon-star fill"></span>
                        <span className="icon-star fill"></span>
                        <span className="icon-star fill"></span>
                        <span className="icon-star fill"></span>
                        <span className="icon-star fill"></span>
                      </div>
                    </div>
                    <div className="street-title">{review.title}</div>
                    <div className="street-content">{review.description}</div>
                    <div className="street-by">
                      <span className="icon-check fill"></span>by <strong>{review.author},</strong> From{" "}
                      {review.location}
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="link-wrapper">
                <Link to="/reviews/" className="link-hvr red">
                  Read all reviews
                </Link>
              </div>
            </div>
            <div className="on-street-cols">
              <TapToPlaySoundVideo
                url={videoUrl}
                width="100%"
                height="auto"
                pip
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="link-wrap-mobile">
                <a href="#" className="link">
                  Read More Reviews
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default WordOnTheStreet
