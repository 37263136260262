import React from "react"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

import Slider from "react-slick"

const KetoQuizAnswered = ({ content }) => {
  const { answerdQuestion, title } = content
  const sliderOptions = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1080,
        settings: {
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
          dots: true,
          centerPadding: "0",
        },
      },
    ],
  }

  return (
    <Section className="keto-answered-section inner-padding">
      <Container>
        <div className="keto-answered-wrapper">
          <h2 className="title">{title}</h2>
          <Slider {...sliderOptions} className="keto-answered-row">
            {answerdQuestion?.map(answer => (
              <div className="keto-answered-cols" key={answer._id}>
                <div className="keto-answered-inner">
                  <div className="keto-answered-image" style={{ backgroundColor: answer.bgColor.hex }}>
                    <Image image={answer.mainImage.asset} isData />
                  </div>
                  <div className="keto-answered-content">
                    <h2 className="keto-answered-title">{answer.title}</h2>
                    <a href={answer?.linkUrl} className="link red" target="_blank">
                      Read article
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </Section>
  )
}

export default KetoQuizAnswered
