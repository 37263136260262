import React from "react"
import { bundleItemsContainer, bundleWrap, closeIcon, emptyBundle } from "./bundleList.module.scss"
import Image from "components/atoms/Image"

const BundleList = ({ bundleItems, removeBundle, children }) => {
  return (
    <div className={bundleItemsContainer}>
      {bundleItems.map((bundle, idx) => {
        return (
          <div className={bundleWrap} key={idx}>
            {!bundle && <div className={emptyBundle}>+</div>}
            {bundle && (
              <>
                <div className={closeIcon} onClick={() => removeBundle(idx)}>
                  x
                </div>
                <Image image={bundle.kmkImage.asset} />
              </>
            )}
          </div>
        )
      })}
      {children}
    </div>
  )
}

export default BundleList
