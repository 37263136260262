import React from "react"

import Container from "../../atoms/Container"
import Section from "../../atoms/Section"

import mwg1 from "../../../assets/images/mwg1.svg"
import mwg2 from "../../../assets/images/mwg2.svg"
import mwg3 from "../../../assets/images/mwg3.svg"
import mwg4 from "../../../assets/images/mwg4.svg"
import mwg5 from "../../../assets/images/mwg5.svg"
import mwg6 from "../../../assets/images/mwg6.svg"

const MadeWithGoodStuff = () => (
  <Section className="made-with-good-stuff-section inner-padding">
    <Container>
      <h2 className="title text-center">Only the good stuff</h2>
      <div className="row">
        <div className="col-sm-12 col-xs-12">
          <div className="client-wrapper">
            <div className="cl-item-wrapper">
              <div className="cl-item">
                <img src={mwg1} alt="fd1" />
                <div className="sub-text">nothing artificial</div>
              </div>
              <div className="cl-item">
                <img src={mwg2} alt="fd2" />
                <div className="sub-text">no added sugar</div>
              </div>
              <div className="cl-item">
                <img src={mwg3} alt="fd3" />
                <div className="sub-text">Low carb</div>
              </div>
              <div className="cl-item">
                <img src={mwg4} alt="fd4" />
                <div className="sub-text">Keto Approved</div>
              </div>
              <div className="cl-item">
                <img src={mwg5} alt="fd5" />
                <div className="sub-text">High Protein</div>
              </div>
              <div className="cl-item">
                <img src={mwg6} alt="fd6" />
                <div className="sub-text">low sugar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Section>
)

export default MadeWithGoodStuff
