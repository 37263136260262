import React, { useContext, useEffect, useState } from "react"
import gtag, { gtagProductMapping } from "../../../../utils/gtag"
import { navigate } from "gatsby"
import Image from "components/atoms/Image"
import SubQtyMobileBadge from "components/atoms/SubQtyMobileBadge"
import ProductIngredientsModal from "components/molecules/ProductIngredientsModal"
import {
  currencyFormat,
  SelectOptionFlavors,
  SelectVarietyOptionQty,
  calDiscountFlavor,
  isFutureDate,
} from "consts/helper"
import CustomSelect from "components/atoms/CustomSelect"
import { StoreContext } from "../../../../context/store"
import ClipLoader from "react-spinners/ClipLoader"
import QuantityPicker from "components/atoms/QuantityPicker"
import ComparePrice from "components/atoms/ComparePrice"
import FlashSale from "components/molecules/FlashSale"

const ProductCollectionsItem = ({ product, shopifyVariants, index, isBundle }) => {
  const {
    flavors,
    name,
    _rawSlug,
    comparePrice,
    shopifyProductId,
    _rawSubscriptionSettings: { initialDiscount },
  } = product

  const displayedFlavorIndex = product?.flavorIndex ? product?.flavorIndex : 0
  const { addToCart, isAddingProductInCart, bundleItems, setBundleItems, setReBuyPopupOpen, enableDiscount } =
    useContext(StoreContext)
  const [selFlavor, setSelFlavor] = useState(flavors[0])
  const [selQty, setSelQty] = useState(1)
  const [showIngredient, setShowIngredient] = useState(false)
  const [shopifyPrice, setShopifyPrice] = useState()
  const [outOfStock, setOutOfStock] = useState(false)
  const [discountPer, setDiscountPer] = useState(0)

  // This is used for when no discount tiers are enabled (sanity > enableDiscount = false)
  const [quantity, setQuantity] = useState(1)

  const normalQty = selFlavor?.subQtyItems[selQty - 1]?.subQty || 1
  const varietyMul = normalQty / selFlavor?.subQtyItems[0]?.subQty || 1

  // useEffect to handle re-rendering of the flavor
  useEffect(() => {
    setSelFlavor(flavors[displayedFlavorIndex])
  }, [flavors])

  useEffect(() => {
    shopifyVariants.map(variant => {
      const selFlavorSku = selQty > 1 ? `${selFlavor.sku}-${varietyMul}` : selFlavor.sku
      if (variant.sku === selFlavorSku) {
        setShopifyPrice(variant.price)
        setOutOfStock(variant.inventoryQuantity === 0)
      }
    })

    const discounts = calDiscountFlavor(selFlavor, flavors)
    if (selFlavor.allowDiscount) {
      if (!selFlavor?.isFlavor) {
        setDiscountPer(discounts[selQty - 1]?.value || 15)
      } else if (selQty === 0) {
        setDiscountPer(0)
      } else {
        setDiscountPer(discounts[selQty - 1]?.value || 0)
      }
    }
  }, [selFlavor, selQty])

  const handleFlavor = event => {
    const tempSelFlavor = flavors.filter(flavor => flavor.flavor === event.label && flavor.sku === event.value)
    tempSelFlavor.length && setSelFlavor(tempSelFlavor[0])
  }

  const handleNavigate = e => {
    const targetId = e?.target?.className
    if (targetId !== "info" && !showIngredient) {
      let selVariant = product.flavors?.[0] || {}
      selVariant = {
        ...selVariant,
        ...shopifyVariants.filter(variant => variant.sku === product.flavors?.[0].sku)?.[0],
      }
      let gtagProduct = {
        ...product,
        variant: selVariant.title,
        variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
        kmkPrice: selVariant.price,
        kmkQuantity: !enableDiscount ? quantity : 1,
        sku: selVariant.sku,
        kmkImage: selVariant.images[0],
        position: index + 1,
      }
      gtag("dl_select_item", {
        event_id: gtagProduct.sku + Math.floor(Date.now() / 1000),
        ecommerce: {
          currencyCode: "USD",
          click: {
            actionField: { list: location.pathname },
            products: [gtagProductMapping(gtagProduct)],
          },
        },
      })
      navigate(`${_rawSlug.current + selFlavor.slug.current.slice(1)}`)
    }
  }

  const handleAddToCart = () => {
    let selFlavorSku = ""
    if (selFlavor.allowDiscount) {
      selFlavorSku = `${selFlavor.sku}${selQty > 1 ? `-${varietyMul}` : ""}`
    } else {
      selFlavorSku = selFlavor.sku
    }
    const selVariant = shopifyVariants.filter(variant => variant.sku === selFlavorSku)[0]

    const calcQty = selFlavor.allowDiscount ? 1 : varietyMul

    const inLineItem = {
      kmkIsSubscribe: false,
      kmkPrice: selVariant.price,
      kmkImage: selFlavor.images[0],
      kmkTitle: selVariant.title,
      kmkSku: selFlavor.sku,
      kmkQuantity: !enableDiscount ? quantity : varietyMul,
      kmkDiscount: initialDiscount,
      kmkAllowDiscount: selFlavor?.allowDiscount,
      charge_interval_frequency: null,
      fulfillment_service: "manual",
      order_interval_frequency: null,
      order_interval_unit: null,
      product_id: shopifyProductId,
      quantity: !enableDiscount ? quantity : calcQty,
      variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
      storefrontId: selVariant.storefrontId,
      categories: product.categories,
      variant: selVariant.title,
      name: product.name,
    }

    setReBuyPopupOpen(true)

    if (selQty && !isBundle) {
      addToCart(inLineItem)
    } else if (selQty && isBundle) {
      const tempBundles = bundleItems.slice(0)
      tempBundles.push(inLineItem)
      setBundleItems(tempBundles)
    }
  }

  // Dates for flash sale functionality
  const showFlashSale = isFutureDate(selFlavor.countdownStartDate)

  return (
    <div
      className={`product-collections-item ${selFlavor?.isSale && "sale"} ${selFlavor?.isNewFlavor && "new-flavor"}`}
    >
      <div className="product-collections-inner">
        <div className="wrapper">
          <div className="product-collections-image" onClick={handleNavigate}>
            {selFlavor?.isSale || selFlavor?.isNewFlavor ? (
              <span
                className={
                  selFlavor?.isSale ? "feature-text feature-text-sale" : "feature-text feature-text-new-flavor"
                }
              >
                {selFlavor?.isSale ? "Sale" : "New Flavor"}
              </span>
            ) : (
              ""
            )}
            {selFlavor?.images[0]?.asset && (
              <>
                <Image
                  image={selFlavor.images[0].asset}
                  imgStyle={{ padding: "0", objectFit: "cover" }}
                  className="product-img"
                />
                <SubQtyMobileBadge qty={selQty - 1} selFlavor={selFlavor} />
              </>
            )}
          </div>
          <div className="product-collections-content" onClick={handleNavigate}>
            <div className="product-title">
              {name} - {selFlavor.flavor}
            </div>
            <div className="product-rating">
              <span className="stamped-product-reviews-badge stamped-main-badge" data-id={shopifyProductId}></span>
            </div>
            <div className="info" onClick={() => setShowIngredient(true)}>
              <i className="icon-info xs"></i>View Ingredients
            </div>
            {showIngredient && (
              <ProductIngredientsModal
                product={product}
                selFlavor={selFlavor}
                onClose={() => setShowIngredient(false)}
              />
            )}

            <div className="price">
              {!enableDiscount ? (
                <ComparePrice price={selFlavor.price} comparePrice={selFlavor.comparePrice} quantity={quantity} />
              ) : (
                <span className={selFlavor.allowDiscount && enableDiscount && discountPer ? "mf-price" : ""}>
                  ${currencyFormat(selFlavor.price * varietyMul)}
                </span>
              )}
              {selFlavor.allowDiscount && enableDiscount && discountPer > 0 && (
                <span className="disc-price">
                  ${currencyFormat(((selFlavor.price * (100 - discountPer)) / 100) * varietyMul)}
                </span>
              )}
            </div>
          </div>
        </div>

        {showFlashSale && <FlashSale value={selFlavor.discountValue} endDate={selFlavor.countdownEndDate} />}

        <div className="product-collections-content more-content">
          <div className="product-dropdown">
            <CustomSelect
              options={SelectOptionFlavors(flavors)}
              placeholder={selFlavor.flavor}
              onChange={handleFlavor}
            />
          </div>
          <div className={`product-cart ${!enableDiscount ? "no-tiers" : "has-tiers"}`}>
            {!enableDiscount ? (
              <QuantityPicker
                qty={quantity}
                qtyIncrease={() => setQuantity(quantity + 1)}
                qtyDecrease={() => quantity > 1 && setQuantity(quantity - 1)}
              />
            ) : (
              <div className="product-qty">
                <CustomSelect
                  options={SelectVarietyOptionQty(selFlavor, flavors, selFlavor?.allowDiscount)}
                  onChange={e => setSelQty(e.value)}
                  value={{
                    label:
                      selFlavor?.subQtyItems[selQty - 1]?.subQty + " " + selFlavor?.subQtyItems[selQty - 1]?.subQtyUnit,
                    value: selQty,
                  }}
                />
              </div>
            )}
            <div className="product-button">
              {isAddingProductInCart && (
                <button className="btn btn-primary btn-disabled">
                  <ClipLoader color={"white"} loading={true} size={30} />
                </button>
              )}
              {!isAddingProductInCart && (
                <button
                  className={`btn ${outOfStock ? "btn-secondary" : "btn-primary"}`}
                  onClick={() => handleAddToCart()}
                  disabled={outOfStock}
                >
                  {!outOfStock ? (isBundle ? "Add To Bundle" : "Add To Cart") : "Out Of Stock"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCollectionsItem
